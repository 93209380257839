<template>
  <div id="webChatVisitorDetailsView" class="d-flex flex-column">
    <div class="d-flex flex-column standard-fields">
      <div
        class="d-flex flex-row justify-content-between align-items-end flex-wrap-reverse"
      >
        <WebChatVisitorDetailsStandardField
          class="name standard-field flex-grow-1"
          iconName="user-circle-outline"
          fieldName="Name"
          scale="1.3"
        >
          <template v-slot:value>
            <div v-if="visitorName">{{ visitorName }}</div>
            <div v-else>Visitor</div>
          </template>
        </WebChatVisitorDetailsStandardField>
        <button
          v-if="isBotConversationClosed"
          @click="editVisitorDetails()"
          aria-label="Edit Visitor Details"
          class="btn btn-light d-flex justify-content-center flex-row flex-grow-1 edit-button mt-1 mb-2 mr-3 ml-3"
        >
          <MgIcon name="edit-icon"></MgIcon>
          <span class="ml-1">EDIT</span>
        </button>
      </div>
      <WebChatVisitorDetailsStandardField
        class="location standard-field"
        iconName="location-pin-outline"
        fieldName="Location"
        scale="1.3"
      >
        <template v-slot:value>
          <div v-if="location">{{ location }}</div>
          <div v-else>Unknown</div>
        </template>
      </WebChatVisitorDetailsStandardField>
      <WebChatVisitorDetailsStandardField
        class="mobile standard-field"
        iconName="phone-outline"
        fieldName="Phone"
        scale="1.3"
      >
        <template v-slot:value>
          <div v-if="phoneNumber">{{ formatPhoneNumber(phoneNumber) }}</div>
          <div v-else>Unknown</div>
        </template>
      </WebChatVisitorDetailsStandardField>
      <WebChatVisitorDetailsStandardField
        class="email standard-field"
        iconName="email-outline"
        fieldName="Email"
        scale="1.3"
      >
        <template v-slot:value>
          <div v-if="emailAddress" class="text-break">{{ emailAddress }}</div>
          <div v-else>Unknown</div>
        </template>
      </WebChatVisitorDetailsStandardField>
      <WebChatVisitorDetailsStandardField
        class="text-opt-in standard-field"
        iconName="checkmark-bubble-outline"
        fieldName="Consent to Text"
        scale="1.3"
      >
        <template v-slot:value>
          <div v-if="consentToText" class="text-break">{{ consentToText }}</div>
          <div v-else>Unknown</div>
        </template>
      </WebChatVisitorDetailsStandardField>
    </div>
    <div
      v-if="studentType || visitorType || dateOfBirth || customFields.length"
      class="d-flex flex-column custom-fields"
    >
      <!-- dateOfBirth, visitorType, and studentType need to be converted to standard fields -->
      <div v-if="dateOfBirth">
        <WebChatVisitorDetailsCustomField
          class="date-of-birth custom-field"
          fieldName="Date Of Birth"
        >
          <template v-slot:value>
            <div class="text-break">{{ dateOfBirth }}</div>
          </template>
        </WebChatVisitorDetailsCustomField>
      </div>
      <div v-if="visitorType">
        <WebChatVisitorDetailsCustomField
          class="visitor-type custom-field"
          fieldName="Visitor Type"
        >
          <template v-slot:value>
            <div class="text-break">{{ visitorType }}</div>
          </template>
        </WebChatVisitorDetailsCustomField>
      </div>
      <div v-if="studentType">
        <WebChatVisitorDetailsCustomField
          class="student-type custom-field"
          fieldName="Student Type"
        >
          <template v-slot:value>
            <div class="text-break">{{ studentType }}</div>
          </template>
        </WebChatVisitorDetailsCustomField>
      </div>
      <div v-if="customFields.length">
        <WebChatVisitorDetailsCustomField
          v-for="customField in customFields"
          :key="customField.name"
          class="custom-field"
          iconName="robot-circle"
          :fieldName="customField.name | snakeCaseToDisplay"
        >
          <template v-slot:value>
            <div>{{ customField.value }}</div>
          </template>
        </WebChatVisitorDetailsCustomField>
      </div>
    </div>
    <WebChatVisitorDetailsLocationHistory
      v-if="locationHistory"
      :conversation="conversation"
    />
  </div>
</template>
<script>
import WebChatVisitorDetailsStandardField from "./WebChatVisitorDetailsStandardField";
import WebChatVisitorDetailsCustomField from "./WebChatVisitorDetailsCustomField";
import WebChatVisitorDetailsLocationHistory from "./WebChatVisitorDetailsLocationHistory";
import { GETTERS } from "@/util/constants";
import analytics from "@/util/analytics";
import {
  visitorFullName,
  visitorLocation,
  visitorPhone,
  visitorEmail,
  visitorType,
  studentType,
  visitorDateOfBirth,
  visitorLocationHistory,
  visitorCustomFields,
  visitorTextOptIn,
} from "@/util/webchat";
import { formatPhoneNumber } from "@/util/phoneNumber";
import MgIcon from "@/components/MgIcon";

export default {
  name: "WebChatVisitorDetailsView",
  components: {
    WebChatVisitorDetailsStandardField,
    WebChatVisitorDetailsCustomField,
    WebChatVisitorDetailsLocationHistory,
    MgIcon,
  },
  props: {
    conversation: {
      type: Object,
      required: false,
    },
  },
  computed: {
    isBotConversationClosed() {
      return this.conversation.closed || this.conversation.agentResponded;
    },
    editUserDetailsFFEnabled() {
      return this.$store.getters[GETTERS.FEATURE_ENABLED](
        "rollout-revamp-chat-details-sidemenu"
      );
    },
    visitorName() {
      return visitorFullName(this.conversation);
    },
    location() {
      return visitorLocation(this.conversation);
    },
    phoneNumber() {
      return visitorPhone(this.conversation);
    },
    emailAddress() {
      return visitorEmail(this.conversation);
    },
    consentToText() {
      return visitorTextOptIn(this.conversation);
    },
    studentType() {
      return studentType(this.conversation);
    },
    visitorType() {
      return visitorType(this.conversation);
    },
    dateOfBirth() {
      return visitorDateOfBirth(this.conversation);
    },
    customFields() {
      return visitorCustomFields(this.conversation);
    },
    locationHistory() {
      return visitorLocationHistory(this.conversation);
    },
  },
  methods: {
    formatPhoneNumber(phoneNumber) {
      return formatPhoneNumber(phoneNumber);
    },
    editVisitorDetails() {
      this.$emit("open-edit-modal");
      analytics.track("Clicked Visitor Details Edit Button");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/utilities";
@import "../../scss/variables";

.standard-fields {
  padding-top: 1rem;
  border-bottom: 1px solid $gray-pane-border;
}

.standard-field {
  padding-left: 1rem;
  padding-bottom: 1rem;
}

.custom-fields {
  padding-top: 1rem;
  border-bottom: 1px solid $gray-pane-border;
}

.custom-field {
  padding-left: 1rem;
  padding-bottom: 1rem;
}

.edit-button {
  height: fit-content;
}

.name {
  @media (max-width: $screen-md) {
    width: 100%;
  }
}

::v-deep .field-icon {
  &#field-icon-location-pin-outline {
    transform: translateX(2px);
  }

  &#field-icon-phone-outline {
    transform: translateX(3px);
  }

  &#field-icon-checkmark-bubble-outline {
    transform: translateX(1px);
  }
}
</style>
