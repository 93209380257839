<template>
  <div>
    <div
      class="message-actions"
      :class="{ isHovered: hoverActive || popoverOpen }"
    >
      <PopoverSearch
        :items="campaigns"
        :icon="'bullhorn'"
        :icon-text="'Campaign'"
        :popover-id="'campaign' + message.messageId"
        :placement="'right'"
        v-if="!message.campaignId && !isHighSpeed"
        @item-picked="addCampaign"
        @popover-state="popoverState"
      ></PopoverSearch>
      <PopoverSearch
        :items="activeAndNotAddedTags()"
        :icon="'tag'"
        :icon-text="'Tag'"
        :popover-id="'tag' + message.messageId"
        :placement="'right'"
        @item-picked="addTag"
        v-if="isIncoming && tagLimit"
        @popover-state="popoverState"
      ></PopoverSearch>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import api from "../../util/api";
import PopoverSearch from "@/components/PopoverSearch";
import { VBTooltip } from "bootstrap-vue";
import {
  ACTIONS,
  INBOX_TYPES,
  GETTERS,
  MESSAGE_DIRECTIONS,
} from "../../util/constants";
import analytics from "@/util/analytics";
import _ from "lodash";

Vue.directive("b-tooltip", VBTooltip);

export default {
  name: "MessageActions",
  store,
  props: {
    message: {
      type: Object,
      required: true,
    },
    hoverActive: {
      type: Boolean,
    },
  },
  components: {
    PopoverSearch,
  },
  data() {
    return {
      isIncoming: this.message.direction === MESSAGE_DIRECTIONS.INCOMING,
      popoverOpen: false,
    };
  },
  computed: {
    multipleTagFeatureFlagEnabled() {
      return this.$store.getters[GETTERS.FEATURE_ENABLED](
        "rollout-multiple-tags"
      );
    },
    campaigns() {
      return this.$store.state.campaigns.active;
    },
    tags() {
      return this.$store.state.tag.tags;
    },
    tagLimit() {
      if (this.multipleTagFeatureFlagEnabled) {
        return (
          !this.message.tags ||
          (this.message.tags && this.message.tags.length < 20)
        );
      } else {
        return (
          !this.message.tags ||
          (this.message.tags && this.message.tags.length === 0)
        );
      }
    },
    isHighSpeed() {
      return (
        this.$store.state.inbox.activeInbox.type === INBOX_TYPES.HIGH_SPEED
      );
    },
  },
  methods: {
    activeAndNotAddedTags() {
      let active = _.filter(this.tags, (t) => !t.archive);
      let currentTags = this.message.tags || [];

      return active.filter(
        (activeTag) =>
          !currentTags.some((currentTag) => activeTag.id === currentTag)
      );
    },
    addTag(item) {
      if (this.isHighSpeed) {
        const params = {
            tagId: item.id,
            contactId: this.$store.state.contact.details.id,
          },
          request = api.put(
            `/HighSpeedMessages/${this.$store.state.inbox.activeInbox.highSpeedId}/${this.message.messageId}/tag`,
            params
          );

        return request.then(() => {
          this.$store.dispatch(ACTIONS.BIND_CONVERSATION);
          analytics.track("Added High-Speed Tag to Message");
        });
      } else {
        let params = {
            tagId: item.id,
            contactId: this.$store.state.contact.details.id,
          },
          request = api.put(
            `/IncomingMessages/${this.message.messageId}/tag`,
            params
          );

        return request.then(() => {
          analytics.track("Added Tag to Message", {
            "Message Id": this.message.messageId,
            "Tag Name": item.name,
            "Number of Tags": this.message.tags.length + 1,
          });
        });
      }
    },
    addCampaign(item) {
      let id = item.id;
      let params = { campaignId: id },
        request;

      if (this.$store.state.inbox.activeInbox.type === INBOX_TYPES.HIGH_SPEED) {
        params = Object.assign(params, {
          messageId: this.message.messageId,
          contactId: this.$store.state.contact.details.id,
        });
        request = api.post(
          `/highspeedinbox/${this.$store.state.inbox.activeInbox.highSpeedId}/campaign`,
          params
        );
      } else {
        let direction = this.message.direction || "";

        if (direction.toLowerCase() === "incoming") {
          request = api.patch(
            `/incomingmessages/${this.message.messageId}`,
            params
          );
        } else {
          request = api.patch(
            `/outgoingmessage/${this.message.messageId}`,
            params
          );
        }
      }

      return request.then(() => {
        this.$store.dispatch(ACTIONS.UPDATE_CAMPAIGN, {
          campaignId: id,
          messageId: this.message.messageId,
        });
        analytics.track("Added Campaign to Message");
      });
    },
    popoverState(state) {
      this.popoverOpen = state;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";
.message-actions {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 1px 3px #ccc;
  display: inline-flex;
  flex-direction: row;
  opacity: 0;
  transition: 0.2s;
  &.isHovered,
  &:focus-within {
    opacity: 1;
  }
}
</style>
