<template>
  <div class="d-flex flex-column h-100 w-100">
    <div class="d-flex flex-column flex-fill overflow-auto page-content">
      <div class="filter-toolbar d-flex flex-wrap">
        <div v-if="onMultipleTeams" class="text-filter">
          <MetricsFilter
            :filterData="filterData"
            @submit-filter="handleFilterSubmit"
            :icon="'filter-solid'"
            :iconText="'Team Filter'"
            :popoverId="'metrics-filter'"
            metricsType="textAnalytics"
          />
        </div>
        <div v-else class="text-filter">
          <h1 class="mb-0 h2 team-name">{{ teamName }}</h1>
        </div>
        <TextTypeFilter
          :textType="textType"
          @submit-filter="handleTextTypeFilterSubmit"
          :icon="'filter-solid'"
          :iconText="'Text Type Filter'"
          :popoverId="'text-type-filter'"
          metricsType="textAnalytics"
          class="text-filter"
        />
        <AnalyticsDatePicker analyticsType="text" class="text-filter" />
      </div>
      <div class="panel-container">
        <MetricsRow :metrics="metrics" />
      </div>
      <div class="panel-container">
        <MetricsChart
          :title="'Messaging Activity'"
          :series="chartSeries"
          :seriesFieldNames="['Incoming', 'Outgoing']"
          :seriesDisplayNames="['Received', 'Sent']"
          :dates="dateParams"
        />
      </div>
      <div class="panel-container">
        <TextAnalyticsBreakdown />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import MetricsFilter from "@/components/MetricsFilter";
import TextTypeFilter from "@/components/TextTypeFilter";
import MetricsRow from "@/components/MetricsRow";
import MetricsChart from "@/components/MetricsChart";
import TextAnalyticsBreakdown from "./TextAnalyticsBreakdown";
import AnalyticsDatePicker from "./AnalyticsDatePicker";
import { FormSelectPlugin } from "bootstrap-vue";
import { ACTIONS } from "@/util/constants";
import store from "@/store";
import analytics from "@/util/analytics";
import _ from "lodash";

Vue.use(FormSelectPlugin);

export default {
  name: "TextAnalyticsPage",
  store,
  components: {
    MetricsFilter,
    TextTypeFilter,
    MetricsRow,
    MetricsChart,
    TextAnalyticsBreakdown,
    AnalyticsDatePicker,
  },
  data() {
    return {
      initialLoad: true,
      contacts: [],
      day: null,
      week: null,
      month: null,
      quarter: null,
      year: null,
      params: { start: "", end: "", timeInterval: "" },
      timestamp: "",
      textType: ["SMS", "MMS"],
    };
  },
  created() {
    this.$store.dispatch(ACTIONS.GET_TEAM_ID);
    this.$store.dispatch(ACTIONS.GET_CURRENT_TEAMS);
    this.$store.dispatch(ACTIONS.GET_CURRENT_INBOXES);
    this.$emit("updateTitle", "Text");
  },
  destroyed() {
    this.$store.dispatch(ACTIONS.CLEAR_METRICS);
  },
  computed: {
    dateParams() {
      return this.$store.state.analytics.startDate
        ? {
            start: this.$store.state.analytics.startDate,
            end: this.$store.state.analytics.endDate,
            timeInterval: this.$store.state.analytics.timeInterval,
          }
        : {};
    },
    chartSeries() {
      return _.get(this.$store.state.analytics.metrics, "series");
    },
    currentTeams() {
      return this.$store.state.analytics.currentTeams.length
        ? this.$store.state.analytics.currentTeams
        : [];
    },
    teamName() {
      return this.$store.state.analytics.currentTeams.length
        ? this.$store.state.analytics.currentTeams[0].name
        : null;
    },
    onMultipleTeams() {
      return this.$store.state.analytics.currentTeams.length > 1;
    },
    messageData() {
      return this.$store.state.analytics.metrics;
    },
    metrics() {
      return this.messageData
        ? this.messageData.metrics
        : [
            { name: "ContactsReached", value: 0 },
            { name: "TotalMessages", value: 0 },
            { name: "Delivered", value: 0 },
            { name: "DeliveredPercentage", value: "0%" },
            { name: "Sent", value: 0 },
            { name: "Received", value: 0 },
            { name: "Incoming", value: 0 },
          ];
    },
    teamId() {
      return this.$store.state.analytics.teamId;
    },
    users() {
      return [];
    },
    inboxes() {
      return [];
    },
    filterData() {
      return {
        teamId: this.teamId,
        currentTeams: this.currentTeams,
      };
    },
  },
  methods: {
    filterType(filter) {
      if (filter.length === 1 && filter === this.filterData.teamId) {
        return "Default Team";
      } else if (filter.length === 1 && filter !== this.filterData.teamId) {
        return "Single Team";
      } else if (filter.length === this.filterData.currentTeams.length) {
        return "All Teams";
      } else if (
        filter.length !== this.filterData.currentTeams.length &&
        filter.length >= 2
      ) {
        return "2 or More Teams";
      }
    },
    handleFilterSubmit(teamfilter) {
      let filterType = this.filterType(teamfilter);
      sessionStorage.setItem(
        "analytics-team-filter",
        JSON.stringify(teamfilter)
      );
      this.$store.dispatch(ACTIONS.GET_ANALYTICS_FILTER, teamfilter);
      analytics.track("Filtered Analytics by Team", {
        "Filter Type": filterType,
        "Team Filter Count": teamfilter.length,
      });
    },
    handleTextTypeFilterSubmit(textType) {
      sessionStorage.setItem(
        "analytics-text-type-filter",
        JSON.stringify(textType)
      );
      let hasMedia = textType.length > 1 ? null : textType.includes("MMS");
      this.$store.dispatch(ACTIONS.GET_METRICS, hasMedia);
      this.$store.dispatch(ACTIONS.GET_ANALYTICS_BREAKDOWN, hasMedia);
      analytics.track("Filtered Analytics by Text Type", {
        "Text Type": textType.length > 1 ? "SMS & MMS" : textType[0],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";
#analyticsBase {
  select {
    max-width: 30%;
    margin: 1% 0;
  }
  ::v-deep .date-picker {
    width: 210px;
  }
}

.page-content {
  padding-top: $grid-gutter-width/2;
}

.filter-toolbar {
  margin: 15px 0;
  @include media-breakpoint-down(md) {
    margin: 0px 0px 15px 0px;
  }
}

.team-name {
  margin-right: 20px;
}

.text-filter {
  @include media-breakpoint-up(md) {
    margin-right: 16px;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    margin-top: 15px;
    margin-right: 0px;
  }

  .dropdown-toggle {
    @include media-breakpoint-down(md) {
      display: flex;
      justify-self: flex-end;
    }
  }
}
</style>
