<template>
  <div id="webChatVisitorDetails" class="d-flex flex-column">
    <WebChatVisitorDetailsHeader :conversation="conversation" class="w-100" />
    <div class="overflow-auto">
      <WebChatVisitorDetailsView
        :conversation="conversation"
        class="w-100"
        @open-edit-modal="showEditModal"
      />
      <WebChatVisitorDetailsFooter :conversation="conversation" class="w-100" />
    </div>
    <WebChatVisitorDetailsEditModal
      modalId="webChatVisitorDetailsEditModal"
      :show="showEditModal_"
      :conversation="conversation"
      @cancelled="hideEditModal"
      @updated="editVisitorDetails"
    />
  </div>
</template>
<script>
import WebChatVisitorDetailsHeader from "./WebChatVisitorDetailsHeader";
import WebChatVisitorDetailsView from "./WebChatVisitorDetailsView";
import WebChatVisitorDetailsFooter from "./WebChatVisitorDetailsFooter";
import WebChatVisitorDetailsEditModal from "./WebChatVisitorDetailsEditModal";
import { ACTIONS } from "@/util/constants";
import analytics from "@/util/analytics";
import Toast from "@/components/Toast";
import api from "@/util/api";

export default {
  name: "WebChatVisitorDetails",
  components: {
    WebChatVisitorDetailsHeader,
    WebChatVisitorDetailsView,
    WebChatVisitorDetailsFooter,
    WebChatVisitorDetailsEditModal,
  },
  props: {
    conversation: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      showEditModal_: false,
    };
  },
  methods: {
    showEditModal() {
      this.$store.dispatch(ACTIONS.GET_WEBCHAT_CONVERSATIONS);
      this.showEditModal_ = true;
    },
    hideEditModal() {
      this.showEditModal_ = false;
    },
    async editVisitorDetails(visitorDetail) {
      try {
        await api.put(
          `/${this.conversation.botId}/hitl/conversation/${this.conversation.conversationId}/setVisitorDetails`,
          visitorDetail
        );
        analytics.track("Visitor Details Updated", {
          "Conversation Id": this.conversation.conversationId,
          "Visitor Details": visitorDetail,
        });
      } catch (error) {
        this.errorToast(
          "There was an error updating this visitor's info. Please try again!"
        );
      } finally {
        this.hideEditModal();
        this.$store.dispatch(ACTIONS.GET_WEBCHAT_CONVERSATIONS);
        this.$store.dispatch(ACTIONS.ADD_WEBCHAT_CONVERSATION_TO_LIST_BY_ID, {
          conversationId: this.conversation.conversationId,
          setSelected: true,
        });
      }
    },
    errorToast(message) {
      let toastBody = this.$createElement(Toast, {
        props: {
          message,
        },
      });
      this.$bvToast.toast([toastBody], {
        variant: "danger",
        noCloseButton: true,
        solid: true,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#webChatVisitorDetails {
  margin-bottom: 92px;
}
</style>
