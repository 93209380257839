<template>
  <div class="d-flex flex-column h-100">
    <SubpageHeader
      data-test-id="contact-name"
      :headerTitle="contact | displayName"
    >
      <template v-slot:menu>
        <b-dropdown
          right
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template #button-content>
            <MgIcon class="text-muted header-menu" name="ellipsis-v" />
          </template>
          <b-dropdown-item v-if="canEdit" v-on:click="editContact()"
            >Edit</b-dropdown-item
          >
          <b-dropdown-item v-if="hasCompose" v-on:click="composeMessage()"
            >Message</b-dropdown-item
          >
          <b-dropdown-divider v-if="canEdit || isAdmin"></b-dropdown-divider>
          <b-dropdown-item
            v-if="isAdmin"
            variant="danger"
            v-on:click="deleteContact()"
            >Delete</b-dropdown-item
          >
        </b-dropdown>
      </template>
    </SubpageHeader>
    <div class="page-content overflow-auto d-flex mh-0">
      <div class="d-flex flex-column flex-fill h-100">
        <ContactDetails></ContactDetails>
        <div
          class="d-flex flex-column flex-md-row mh-0"
          :class="{ 'field-and-messages-section': canSMSFromChat }"
        >
          <div class="d-flex flex-grow-1 flex-column fields">
            <BCard class="mb-4 mb-md-0" tabindex="0">
              <template #header>
                <div class="d-flex align-items-center">
                  <MgIcon
                    class="d-flex ml-n2 mr-2"
                    name="contact-card-light"
                    scale="1.2"
                  ></MgIcon>
                  <h2 class="card-title">Fields</h2>
                </div>
              </template>
              <h3 class="fields-header">
                <strong>Activity Fields</strong>
              </h3>
              <ContactActivity></ContactActivity>
              <h3 class="fields-header mt-4">
                <strong>Custom Fields</strong>
              </h3>
              <ContactCustomFields></ContactCustomFields>
            </BCard>
          </div>
          <div class="d-flex flex-grow-1 flex-column history">
            <BCard class="mb-4 mb-md-0 h-100" no-body>
              <template #header>
                <div class="d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <MgIcon
                      class="d-flex ml-n2 mr-2"
                      name="comments"
                      scale="1.25"
                    ></MgIcon>
                    <h2 class="card-title">{{ cardTitle }}</h2>
                  </div>
                  <div class="d-flex align-items-center card-header-action">
                    <MgIcon
                      name="info-circle"
                      class="mr-2"
                      v-if="history.length > 0"
                      v-b-tooltip="{ title: help.contact.messageRetention }"
                    ></MgIcon>
                    <BButton
                      v-on:click="exportHistory()"
                      v-if="history.length > 0"
                      variant="light"
                      size="sm"
                      >Export All</BButton
                    >
                  </div>
                </div>
              </template>
              <BCardBody>
                <ContactHistory
                  :contact-id="contactId"
                  :profile="profile"
                ></ContactHistory>
              </BCardBody>
            </BCard>
          </div>
          <div
            v-if="canSMSFromChat"
            class="d-flex flex-column flex-md-row chat-section mobile"
          >
            <div class="d-flex flex-grow-1 flex-column">
              <ContactChatHistory
                :contact-id="contactId"
                class="h-100 mb-4 mb-md-0"
              />
            </div>
          </div>
        </div>
        <div
          v-if="canSMSFromChat"
          class="d-flex flex-column flex-md-row chat-section desktop"
        >
          <div class="d-flex flex-grow-1 flex-column">
            <ContactChatHistory
              :contact-id="contactId"
              class="h-100 mb-4 mb-md-0"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "@/store";
import api from "@/util/api";
import { ACTIONS, HELP } from "@/util/constants";
import { BCard, BCardBody, BButton, VBTooltip } from "bootstrap-vue";
import ContactDetails from "@/components/ContactDetails";
import ContactCustomFields from "@/components/ContactCustomFields";
import ContactHistory from "@/components/ContactHistory";
import ContactActivity from "@/components/ContactActivity";
import SubpageHeader from "@/components/SubpageHeader";
import MgIcon from "@/components/MgIcon";
import FileSaver from "file-saver";
import eventBus from "@/util/eventBus";
import analytics from "@/util/analytics";
import ContactChatHistory from "@/components/ContactChatHistory";

Vue.directive("b-tooltip", VBTooltip);

export default {
  name: "ContactProfile",
  store,
  components: {
    BButton,
    BCard,
    BCardBody,
    ContactHistory,
    ContactDetails,
    ContactCustomFields,
    ContactActivity,
    SubpageHeader,
    MgIcon,
    ContactChatHistory,
  },
  data() {
    return {
      help: HELP,
    };
  },
  props: {
    contactId: {
      required: true,
    },
    createdOn: {
      type: Date,
      required: false,
    },
  },
  async created() {
    this.$store.dispatch(ACTIONS.SELECT_CONVERSATION, this.contactId);
    this.getContact();
    eventBus.addEventListener("contactUpdated", this.getContact);
  },
  destroyed() {
    eventBus.removeEventListener("contactUpdated", this.getContact);
  },
  computed: {
    contact() {
      return this.$store.state.contact.details;
    },
    history() {
      return (
        this.$store.state.contact.history &&
        this.$store.state.contact.history.results
      );
    },
    isAdmin() {
      return this.$store.state.config.isAdmin;
    },
    canEdit() {
      return (
        this.isAdmin ||
        this.contact.source === "Staff" ||
        this.contact.source === "ColdContact" ||
        this.contact.source === "WhatsAppColdContact"
      );
    },
    profile() {
      return JSON.parse(window.localStorage.profile) || null;
    },
    canSMSFromChat() {
      return (
        this.profile.currentDepartment.teamHasChat &&
        this.profile.currentDepartment.userIsChatAgent
      );
    },
    cardTitle() {
      return this.profile.currentDepartment.teamHasChat
        ? "Text History"
        : "Message History";
    },
    hasCompose() {
      return this.profile.inboxes.length > 0;
    },
  },
  methods: {
    getContact() {
      this.$store.dispatch(ACTIONS.GET_CONTACT, {
        id: this.contactId,
        firstName: this.firstName,
      });
    },
    exportHistory() {
      let id = this.contactId;
      api.get("students/" + id + "/export-history").then(function (history) {
        var data = new Blob(history, { type: "text/csv;charset=utf-8" });
        FileSaver.saveAs(data, "contact_history_" + id + ".csv");
      });
      analytics.track("Clicked Export in Message History");
    },
    editContact() {
      this.$emit("edit", this.contact.id);
      analytics.track("Clicked Edit from Contact Profile", {
        "Contact Type": this.contact.source,
        "Contact Id": this.contact.id,
      });
    },
    deleteContact() {
      this.$emit("delete", this.contact.id);
      analytics.track("Clicked Delete from Contact Profile");
    },
    composeMessage() {
      let contacts = [
        {
          id: this.contact.id,
          tagText: this.contact.firstName
            ? `${this.contact.firstName} ${this.contact.lastName}`
            : this.contact.mobileNumberFormatted,
        },
      ];
      this.$emit("compose", { contacts });
      analytics.track("Clicked Message from Contact Profile");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/utilities";

.fields {
  @include media-breakpoint-up(md) {
    padding-right: 30px;
    width: 33.3%;
    .card {
      min-height: 100%;
    }
    .card-body {
      overflow: auto;
    }
  }
}

.history {
  .card-body {
    padding-top: 0;
    .load-more {
      margin: 0 -20px;
    }
  }
  @include media-breakpoint-up(md) {
    width: 66.7%;
    .card {
      min-height: 100%;
    }
    .card-body {
      overflow: auto;
    }
  }
}

.fields-header {
  border-bottom: 1px solid #e2e2e2;
  font-size: $font-size-base;
  padding-bottom: 0.3rem;
}

.card-header-action {
  margin: -5px 0;
}

.field-and-messages-section {
  margin-bottom: 30px;
  @include media-breakpoint-up(md) {
    height: 100%;
  }
}

.chat-section {
  @include media-breakpoint-up(md) {
    height: 23%;

    &.mobile {
      display: none !important;
    }
  }

  @include media-breakpoint-down(md) {
    &.desktop {
      display: none !important;
    }
  }
}

.chat-history-card {
  margin: 30px 0 !important;
}
</style>
